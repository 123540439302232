import * as React from 'react';

import styles from './BookingWidgetSectionHeader.module.css';

interface Props {
  text: string;
}

export const BookingWidgetSectionHeader = ({ text }: Props) => {
  return (
    <div className={styles['section-header']}>
      <p className="base-key__text">{text}</p>
    </div>
  );
};

export default BookingWidgetSectionHeader;
